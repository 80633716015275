<template>
  <div class="page-login-desktop">

    <Table
        label="لیست کاربران"
        icon="mdi-format-list-bulleted"
        :table="table">
      <template slot="header">
        <div class="d-flex align-center justify-end">

          <v-btn
              @click="filters.modal = true"
              elevation="0"
              large
              color="main"
              class="white--text"
          >
            جستجو
          </v-btn>
        </div>
      </template>
      <tr
          v-for="(item,index) in table.items"
          :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">
          {{ item.username }}
          <v-chip
              small
              :color="item.type.color">
            {{ item.type.name }}
          </v-chip>
        </td>
        <td class="text-center">{{ item.agencyname }}</td>
        <td class="text-center">{{ item.phoneNumber }}</td>
        <td class="text-center">{{ item.phone }}</td>
        <td class="text-center">{{ item.address }}</td>
        <td class="text-center">{{ item.created_at }}</td>
        <td class="text-center">
          <v-chip color="success" text-color="white" v-if="item.status">
            فعال
          </v-chip>
          <v-chip color="error" text-color="white" v-else>
            غیرفعال
          </v-chip>
        </td>
        <td class="text-center">{{ item.lastvisit }}</td>

        <td class="text-center pa-0" style="width: 150px">

          <EditButton
              @click="item.actions.edit"
          />

          <DeleteButton

              @click="item.actions.delete"
          />

          <MessageButton
              @click="item.actions.replay"
          />
        </td>
      </tr>
    </Table>

    <Pagination
        v-model="filters.skip"
        :key="filters.skip"
        :totalItems="table.totalCount"
        :perPage="filters.take"
        @change="(page)=>{fetchItems({skip: page})}"
    />

    <NewMessage
        @onSubmit="()=>{
          newMessage.modal=false;
        }"
        :messageData="newMessage"
    />


    <v-dialog max-width="500" v-model="filters.modal">
      <v-card>
        <v-card-title>
          <div class="font-weight-bold text-body-1">
            جستجو کاربران
          </div>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col :cols="6">
              <v-text-field
                  label="نام و نام خانوادگی"
                  outlined
                  v-model="filters.Nameandfamily"
                  hide-details
              />
            </v-col>
            <v-col :cols="6">
              <v-text-field
                  label="موبایل"
                  outlined
                  v-model="filters.mobile"
                  hide-details
              />
            </v-col>

            <v-col :cols="6">
              <v-text-field
                  label="نام آژانس"
                  outlined
                  v-model="filters.Agencyname"
                  hide-details
              />
            </v-col>

            <v-col :cols="6">
              <v-text-field
                  label="تلفن"
                  outlined
                  v-model="filters.phone"
                  hide-details
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                  label="آدرس"
                  outlined
                  v-model="filters.Adress"
                  hide-details
              />
            </v-col>


          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn color="warning" @click="resetFilters">
            ریست
          </v-btn>
          <v-btn color="primary" @click="fetchItems">
            جستجو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteItem.modal" width="500">
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این مشاور مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="deleteItem.modal=false"
              large text color="main">
            لغو
          </v-btn>


          <v-btn
              :loading="deleteItem.loading"
              :disabled="deleteItem.loading"
              @click="destroyItem"
              elevation="0"
              large color="error">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </div>
</template>

<script>
import Table from "@/components/Table"
import EditButton from "@/components/EditButton"
import DeleteButton from "@/components/DeleteButton"
import MessageButton from "@/components/MessageButton"
import NewMessage from "@/components/NewMessage"
import Pagination from "@/components/Pagination"
import {deleteUser, fetchItems} from "@Newfiling/module-user/src/api";
import moment from "jalali-moment";
import {getDataFromUrl, updateUrlQueries} from "@Newfiling/Services";
import {deleteFile} from "@Newfiling/module-file/src/api";

export default {
  name: 'DesktopLogin',

  components: {
    Table,
    Pagination,
    NewMessage,
    EditButton,
    MessageButton,
    DeleteButton
  },

  data() {
    return {
      types: {
        "1": {
          name: 'مدیر',
          color: 'primary'
        },
        "2": {
          name: 'مشاور',
          color: 'warning'
        },
      },
      filters: {
        modal: false,
        Nameandfamily: null,
        skip: 0,
        take: 10,
        accountType: 0,
        Codeajans: -2,
        mobile: "",
        phone: "",
        Agencyname: "",
        Adress: "",
      },

      table: {
        headers: [
          '#',
          'نام',
          'آژانس',
          'موبایل',
          'تلفن ثابت',
          'آدرس',
          'تاریخ ثبت نام',
          'وضعیت',
          'آخرین بازدید',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },

      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },

      viewMessage: {
        modal: false,
        body: "",
      },

      newMessage: {
        modal: false,
        phoneNumber: null,
      }
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست کاربران',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(() => {
      this.filters = {
        ...this.filters,
        ...getDataFromUrl(this.$route),
      }
      this.fetchItems();
    })
  },

  methods: {
    resetFilters() {
      this.filters = {
        ...this.filters,
        modal: false,
        Nameandfamily: null,
        skip: 0,
        take: 10,
        accountType: 0,
        Codeajans: -2,
        mobile: "",
        phone: "",
        Agencyname: "",
        Adress: "",
      }
      this.fetchItems();
    },
    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters
      }
      this.filters.modal = false;
      this.table.loading = true;
      updateUrlQueries(this.$route.path, this.filters);
      try {
        const res = (await fetchItems({
          ...this.filters,
          skip: this.filters.skip * this.filters.take,
        }))?.data || {
          message: [],
          recordcount: 0
        };
        this.table.items = res?.message.map(item => {
          return {
            username: item.nameandfamily,
            type: this.types[item.accountType || "1"],
            accountType: item.accountType,
            agencyname: item.agencyname,
            phoneNumber: item.mobile,
            phone: item.phone,
            address: item.adress,
            created_at: moment(item.dateofRegistration).locale('fa').format('jYYYY-jMM-jDD'),
            status: item.userstatus,
            lastvisit: item.lastvisit,
            actions: {

              delete: () => {
                this.deleteItem = {
                  ...this.deleteItem,
                  id: item.id,
                  modal: true,
                  loading: false,
                }
              },

              edit: () => {
                this.$router.push('/panel/users/edit/' + item.id)
              },
              replay: () => {
                this.newMessage = {
                  modal: true,
                  phoneNumber: item.mobile.substring(1),
                }
              }
            }
          }
        });
        this.table.totalCount = res?.recordcount;
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    },
    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        await deleteUser(this.deleteItem.id);
        this.$toast.success('کاربر مورد نظر با موفقیت حذف شد.');
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        }
        await this.fetchItems();
      } catch (e) {
        console.log(e)
      }
      this.deleteItem.loading = false;
    },
  }

}
</script>
