import {httpProvider} from "@Newfiling/Services";

export const submitFile = (data) => {
    return httpProvider.post('/Admim/Fileregistration', data);
};

export const editFile = (id, data) => {
    return httpProvider.put(`/Admin/FileList/${id}`, data);
};

export const fetchFiles = (data) => {
    return httpProvider.post('/Filefilter', data);
};

export const deleteFile = (fileId) => {
    return httpProvider.post(`/Admin/FileList/DeleteFile`, {
        id: fileId
    });
};

export const fetchFile = (data) => {
    return httpProvider.post(`/eAdminReceivefile`, data);
};

export const updateFileStatus = (data) => {
    return httpProvider.post(`/QuickconfirmedfileFileregistration`, data);
};

export const updateFileTransactionStatus = (data) => {
    return httpProvider.post(`/QuickconfirmedfileFileregistration2`, data);
};

export const searchFile = (data) => {
    return httpProvider.post(`/Searchfiles`, data);
};

export const searchLocations = (text) => {
    return httpProvider.get(`https://site.eskantehran.ir/api/parsimapSuggestion?text=${text}`);
};

export const getCoordinate = (locationId) => {
    return httpProvider.get(`/mapCoordinates?id=${locationId}`,);
};

export const fileStatistics = (data) => {
    return httpProvider.post(`/Admin/FileList/FileStat`, data);
};

export const fetchFilesWithMobile = (mobile) => {
    return httpProvider.post(`/Relatedfiles`, {
        mobile,
        take: 999,
        skip: 0,
        Sortparameter: 0
    });
};

export const fetchFilesFromDivar = (data) => {
    return httpProvider.post('https://site.eskantehran.ir/api/divarlist', data);
};

export const fetchFileFromDivar = (id) => {
    return httpProvider.get(`https://site.eskantehran.ir/api/RealEstateListings/${id}`);
};


export const generateImageWithWatermark = (url) => {
    return httpProvider.get(`https://site.eskantehran.ir/api/ImageFetch?imageUrl=${url}`);
};

export const updateDivarFileStatus = (id,status) => {
    return httpProvider.put(`https://site.eskantehran.ir/api/RealEstateListings/${id}`, {
        "id": id,
        "publicationStatus": status
    });
};


