import {httpProvider} from "@Newfiling/Services";

export const fetchItems = (data) => {
    return httpProvider.post('/eAdminfinduser', data);
};

export const adminCreatepropertymanager = (data) => {
    return httpProvider.post('/eAdminCreatepropertymanager', data);
};

export const adminCreaterealestateconsultant = (data) => {
    return httpProvider.post('/AdminCreaterealestateconsultant', data);
};

export const find = (id) => {
    return httpProvider.post('/eAdminfinduser', {
        userid: id
    });
};

export const update = (data) => {
    return httpProvider.post('/AdminEdituser', data);
};

export const adminOnlineUsers = (data) => {
    return httpProvider.post('/Adminonlineuser', data);
};

export const deleteUser = (id) => {
    return httpProvider.get('/Removetheconsultant/' + id,);
};





